.toast-inner {
  background-color: #E3F2FD !important;
  color: #1976D2 !important;
}

.toast-action {
  border-radius: 5px !important;
  background-color: #1976D2 !important;
  color: #FFFFFF !important;
  margin-left: 25px !important;
}