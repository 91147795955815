
$cookieconstent-padding: 1rem 0 !default;
$cookieconstent-space: 0.5rem 1rem !default;
$cookieconstent-position: fixed !default;
$cookieconstent-background: #333333 !default;
$cookieconstent-z-index: 9999 !default;
$cookieconstent-width: 100% !default;

.cookie-consent {
  position: $cookieconstent-position;
  z-index: $cookieconstent-z-index;
  width: $cookieconstent-width;
  padding: $cookieconstent-padding;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  background: $cookieconstent-background;
}

.cookie-consent-transition {
  &-leave-active {
    transition: transform 0.75s;
    transition-timing-function: cubic-bezier(0.75, 0, 0, 1);
  }
  &-leave-to {
    transform: translateY(100%);
  }
}

.cookie-consent {
  left: 0;
  right: 0;
  bottom: 0;
}
